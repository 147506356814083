.container-home{
	
	#home {
		img {
			width: 100%;
		}
	}

	img {
		margin: 0 auto;
	}

	.navbar-right {
	    width: 100%;
	    position: absolute;
	    z-index: 999;
	    background-color: rgba(255,255,255, 0.9);
	    display: block !important;

	    .navbar-collapse {
	    	padding: 0;

	    	.navbar-nav {

		    	> li:last-child > a {
					padding-right: 0;
				}

		    	li a {
		    		background: none !important;
		    		padding: 18px 25px;
		    	}

		    	.dropdown {

		    		li {

		    			a {
		    				padding: 5px 20px;
		    			}
		    		}
		    	}
		    }
	    }
	}
}

@media (max-width: 767px) {
	.container-home {
		.navbar-collapse {
			background-color: white;
		}
	}
}

@media (min-width: 768px) {
	.container-home {

		.navbar-nav {
			float: right;
		}
	}

	.navbar-header {
		display: none;
	}
}