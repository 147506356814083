footer {
	.newsletter {
		background-color: #999392;
		padding: 30px 0;

		h2 {
			color: white;
			margin: 0 0 30px 0;
			text-align: center;
		}

		.form-control {
			border-radius: 0;
		}

		.btn {
			border-radius: 0;
		    color: white;
		    background-color: #FF973F;
		    border-color: white;
		    font-family: "Barlow-Bold";
		    padding: 6px 20px;
		    width: 100%;
		}

		::placeholder {
			color: #FF973F;
		}

		.checkbox-inline {
			color: white;
		}
	}

	.container-centro {
		padding: 50px 0;
    	background-color: #FF973F;

    	h2 {
    		margin: 0;
    		color: white;
    		text-align: center;
    	}

    	.center {
    		font-family: "Barlow-ExtraBold";
    	}
	}

	.container-salud {
		background-color: #FAFAFA;
		padding: 50px 0;

		.align-center {
			text-align: center;
			margin-bottom: 50px;
		}

		.componentes-salud {
			display: flex;
			justify-content: space-between;

			.componente {
				text-align: center;
				margin-bottom: 30px;

				.container-img {
					height: 40px;
					margin-bottom: 10px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				span {
					color: #999392;
					font-family: "Barlow-Bold";
				}
			}
		}
	}

	.titulo {
		font-family: "Barlow-ExtraBold";
		color: #FF973F;
		margin: 0 0 15px 0;
	}

	.container-nace {
		padding: 100px 0;

		p {
			text-align: left;

			span {
				font-family: "Barlow-Bold";
			}
		}

		img {
			width: 100%;
		}
	}

	.container-video {
		background-color: rgba(255,151,63,0.12);
		padding: 50px 0;
		text-align: center;

		.video-container {
			position:relative;
			padding-bottom:56.25%;
			padding-top:30px;
			height:0;
			overflow:hidden;
		}

		.video-container iframe, .video-container object, .video-container embed {
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}

	.nuestros-clientes {
		padding: 50px 0;
		background-color: #FAFAFA;

		h3 {
			text-align: center;
			margin-bottom: 50px;
		}

		.flex-wrap {
			display: flex;
			justify-content: space-between;

			img {
				float: left;
    			margin-right: 10px;
			}

			ul {
				list-style-type: none;

				li {
					margin-bottom: 10px;
					font-family: "Barlow-Bold";
					color: #999392;
				}
			}
		}
	}

	.somos {
		padding: 50px 0;

		h3 {
			text-align: center;
			margin-bottom: 50px;

			span {
				color: #999392;
			}
		}

		img {
			margin: 0 auto;
		}
	}

	.container-info {
		
		.dis-flex {
			display: flex;
			flex-wrap: wrap;

			.info-contacto {
				background-color: #FF973F;
				padding: 50px 30px;
				width: 100%;

				.jc-around {
					display: flex;
					justify-content: space-around;
					flex-wrap: wrap;

					.texto-contact {
						text-align: left;

						h3 {
							color: white;
							font-family: "Barlow-Bold";
						}

						p {
							color: white;
							margin: 0;
							font-family: "Barlow-Medium";
							text-align: left;

							a {
								color: white;
							}
						}
					}

					.social-footer {
						display: flex;
						flex-direction: column-reverse;
						margin-top: 30px;

						ul {
							list-style-type: none;
							margin: 0;
							padding: 0;

							li {
								float: right;
								margin: 0 4px;
							}
						}
					}
				}
			}

			.container-map {
				width: 100%;
			}
		}
	}

	.navbar-nav {
		margin: 20px auto;
		display: flex;
	    justify-content: center;
	    flex-wrap: wrap;
	    float: none;

		a {
			color: #999392 !important;
		}
	}

	@media (max-width: 630px) {
		.componentes-salud {
			flex-wrap: wrap;

			.componente {
				width: 50%;
			}
		}
	}

	@media (min-width: 768px) {
	    .navbar .navbar-nav {
	        display: inline-block;
	        float: none;
	        vertical-align: top;
	    }

	    .navbar .navbar-collapse {
	        text-align: center;
	    }
	}

	@media (max-width: 991px) {
		.flex-wrap {
			flex-wrap: wrap;
		}
	}

	@media (min-width: 992px) {
		.info-contacto {
			width: 60% !important;
		}

		.container-map {
			width: 40% !important;
		}
	}
}