.flex {
	display: flex;
	margin-bottom: 30px;

	.img-nosotros {
		display: flex;
		justify-content: center;
		align-items: start;
		margin-right: 25px;
	}

	.texto-nosotros {

		h5 {
			font-family: "Barlow-Bold";
			color: #FF973F;
			margin-bottom: 20px;
		}

		p {
			text-align: left;	
		}
	}
}

@media (max-width: 991px) {
	.flex {
		flex-wrap: wrap;

		.img-nosotros {
			width: 100%;
		}
	}
}