#Home {
	div.active {
		> img {
			width: 100%;
		}
	}
}

.carousel-control {
	display: flex;
	justify-content: center;
	align-items: center;
}

.carousel-indicators {
	left: 90%;
	margin: auto;
	width: auto;
}

.carousel-indicators .active {
	background-color: #41B6C1;
	border: 1px solid white;
}