.img-header {
	position: relative;

	img {
		width: 100%;
	}

	div {
		position: absolute;
		top: 50%;
		margin-top: -19.5px;
		width: 100%;

		h2 {
			margin: 0;
			font-family: "Barlow-Bold";
			color: white;
			text-align: center;
			text-transform: uppercase;
		}
	}
}

.content-subpage {
	margin: 75px auto;
	color: #999392;

	p {
		text-align: justify;

		span {
			font-family: "Barlow-Bold";
		}
	}

	.color-orange, span {
		color: #FF973F;
	}

	.diff-margin {
		margin-bottom: 10px;
	}

	h3 {
		color: #FF973F;
		font-family: "Barlow-Bold";
	}
}

.default-page {

	p {
		text-align: left;
	}
}