.container-categories {
	background-color: #FAFAFA;
	padding: 50px 0;

	h4 {
		font-family: "Barlow-Bold";
		color: #999392;
		margin-top: 0;
	}


	.container-category {
		text-align: center;
		background-color: #FF973F;
		color: white;
		padding: 10px 0;
		display: block;
		font-size: 16px;
		margin: 10px 0;
	}
}

.container-posts {
	background-color: rgba(255, 151, 63, 0.12);
	padding: 50px 0;

	.container-post {
		margin-bottom: 30px;
		background-color: white;
    	padding: 20px;
    	color: #666666;

    	p {
    		text-align: left;
    		margin-bottom: 15px;

    		img {
    			margin-right: 10px;
    		}
    	}

		.img-post {
			width: 100%;
			margin-bottom: 15px;
		}
	}
}

.show-post {

	.img-post {
		width: 100%;
	}

	h4 {
		font-family: "Barlow-Bold";
		color: #666666;
		margin-top: 0;
	}

	.icon {
		margin-right: 15px;
	}

	p {
		text-align: justify;
		margin-top: 25px;
		margin-bottom: 50px;
	}

	.data-post {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 30px;
	}
}

.container-tags {

	a {
		float: right;
		padding: 5px 25px;
		background-color: #FF973F;
		color: white;
		margin-left: 8px;
	}
}