#slider-text {
  width: 100%;
  height: 300px;
  overflow: hidden;
  background-image: url('https://s3-eu-west-1.amazonaws.com/homeostasi/assets/media/recurso6.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

	.slides {
		position: relative;
		margin: 0;
		padding: 0;
		height: 100%;

		.slide-text {
			position: absolute;
			list-style-type: none;
			text-align: center;
			width: 100%;
			top: 50%;
			left: 0;
			font-family: "Barlow-ExtraBold";
			font-size: 24px;
			color: white;
			margin-top: -17px;

			span {
				font-family: "Barlow-Regular";
				font-size: 16px;
			}
		}
	}
}

@media(max-width: 600px) {
	#slider-text {

		.slides {

			.slide-text {
				font-size: 14px;
			}
		}
	}
}