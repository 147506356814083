.background-orange {
	background-color: rgba(255, 151, 63, 0.12);
	padding: 75px 0 100px 0;

    .btn {
    	width: 100%;
    	background-color: #FF973F;
    	border-color: #FF973F;
    	color: white;
    	font-family: "Barlow-Bold";
    	border-radius: 0;
    	font-size: 18px;
    }

    .form-control {
    	border-radius: 0;
    	font-family: "Barlow-Regular";
    }

    .form-control:focus {
    	border-color: #41B6C1;
    	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(65,182,193, 0.6);
    }
}