.navbar-default {
	background-color: white;

	.navbar-nav {
		margin: 0;

		> li > a {
			background-color: white;
			color: #999392;
			padding: 18px 25px;
		}

		> .active > a {
			color: #FF973F;
			background: none;

			&:hover {
				background: none;
			}
		}

		.dropdown-menu {

			> .active {
				
				> a {
					background-color: #FF973F;
				}
			}
		}
	}
}