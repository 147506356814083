body {
	font-family: "Barlow-Regular";

	.hr-top {
		height: 8px;
		background-color: #FF973F;
		margin: 0;
		border: 0;
	}

	.page-title {
		text-align: center;
		color: #FF973F;
		font-family: "Barlow-ExtraBold";
		margin: 75px auto 30px auto;
	}

	p {
		text-align: center;
		color: #999392;
		margin-bottom: 30px;
	}

	.margen-inferior {
		margin-bottom: 50px;
	}
}