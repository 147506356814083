.cookies-box {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  background-color: #333333;
  color: #fff;
  font-size: 12px;
  padding:5px;
  display: flex;
  justify-content: center;

 a{
    color: #FF973F;
  }

 .cookies-accept {
      background-color: #FF973F;
      padding: 0px 5px;
      margin: 0px 10px;
      color: #FFF;
  }

}