@font-face {
  font-family: 'Barlow-Black';
  src: url('../../mytheme/fonts/Barlow-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow-Regular';
  src: url('../../mytheme/fonts/Barlow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow-ExtraBold';
  src: url('../../mytheme/fonts/Barlow-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow-Light';
  src: url('../../mytheme/fonts/Barlow-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow-Bold';
  src: url('../../mytheme/fonts/Barlow-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow-Medium';
  src: url('../../mytheme/fonts/Barlow-Medium.ttf') format('truetype');
}