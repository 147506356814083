.header {

	.flex-between {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;
		align-items: center;
		flex-wrap: wrap;

		.flex-end {

			.switchLanguage {
				overflow: hidden;
				margin-bottom: 25px;

				img {
					width: 18px;
				}
			}

			ul {
				list-style-type: none;
				margin: 0;

				li {
					float: right;
					margin: 0 4px;
				}
			}

			.flex {
				display: flex;
				width: 100%;
				flex-wrap: wrap;
				align-items: center;

				.contact {

					li {
						margin: 0 8px;

						a {
							color: #999392;
							text-decoration: none;

							i {
								color: #FF973F;
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}

.custom-menu {
	width: 100%;
	background-color: #FAFAFA;
	margin-top: 25px;

	.navbar-nav {
		> li > a {
			background-color: #FAFAFA;
		}
	}
}

@media (max-width: 496px) {
	.header {
		.flex-between {
			justify-content: center;

			.switchLanguage {
				display: flex;
				justify-content: center;
				margin-top: 15px;
				padding-left: 0;
			}

			.flex {
				flex-flow: column;
			}

			.contact {
				margin-bottom: 10px !important;
			}
		}
	}
}

@media (max-width: 760px) {
	.header {
		.flex-end {
			width: 100%;

			.flex {
				justify-content: space-between;

				ul {
					padding-left: 0;
				}
			}
		}
	}
}

@media (min-width: 768px) {
	.custom-menu {
		margin-right: 0;
		float: none !important;

		.navbar-nav {
			float: right;
		}
	}
}